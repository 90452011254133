h1{
    margin-left: 5%;
}

.home-container {
    display: flex;
    min-height: 90vh; /* Asegura que tome al menos la altura de la pantalla */
}

.nav-menu-wrapper {
    width: 60px; /* Mantén la anchura original del NavMenu */
}

.content-wrapper {
    flex-grow: 1;
    padding: 20px; /* Espacio alrededor del contenido */
}
  



