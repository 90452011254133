.custom-modal-width .modal-dialog {
    max-width: 650px; /* Ajusta el ancho del modal aquí */
    width: 60%; /* Asegura que tome el ancho máximo permitido */
  }
  
.container-modal {
    display: flex;
    align-items: center; /* Alinea verticalmente los elementos al centro */
    gap: 10px; /* Espacio entre los dos divs, ajusta según sea necesario */
    bottom: 10px;
  }
  
  .div1 img {
    height: 80px;
    width: 150px;
    display: block;
  }
  
  .div1 .comprobante {
    color: red;
    text-align: center;
  }

  .div2{
    margin-left: 15%;
  }
  