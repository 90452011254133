.box-credit {
    height: 160px;
    background-color: white;
    margin: 20px 20px auto;
    box-shadow: 0 10px 6px -6px #777;
    padding: 15px;
    transition: all 0.3s ease;
    border: 1px solid #8ea591;
    font:  sans-serif;
   
  }
  
  .box-credit.wide {
    width: 92%; /* Ocupará el 90% del contenedor cuando sea ancho */
    margin-left: 4%;
  }
  
  .box-credit.narrow {
    width: 45%; /* Ocupará el 45% del contenedor cuando sea estrecho */
    display: inline-block; /* Permite que se alineen uno al lado del otro */
  }
  


  .History {
    max-height: 1200px; /* Ajusta esta altura según tus necesidades */
    overflow-y: auto; /* Habilita el scroll vertical */
  }
  