.container-history {
    display: flex;
    flex-direction: column; /* Apila los elementos verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
    justify-content: flex-start; /* Alinea los elementos en la parte superior del contenedor */
    height: 85%;
    width: 85%;
    background-color: white;
    margin-left: 10%;
    margin-top: 1%;
}

.title, .History {
    width: 100%; /* Asegura que cada sección ocupe todo el ancho del contenedor */
    margin-bottom: 20px; /* Espacio entre los bloques para evitar que se peguen entre sí */
}

.title h3, .History h3 {
    text-align: center; /* Alinea el texto a la izquierda */
    margin-left: 20px; /* Añade un pequeño margen izquierdo para separación */
}

.BoxCredit {
    margin-bottom: 10px; /* Espacio entre los BoxCredit dentro de la sección .title */
}

