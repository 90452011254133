.container-creditApply {
    display: flex;
    flex-direction: column; /* Apila los elementos verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
    justify-content: flex-start; /* Alinea los elementos en la parte superior del contenedor */
    height: 100%;
    width: 85%;
    background-color: white;
    margin-left: 10%;
    margin-top: 1%;
}

.container-creditApply h1{
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: 0%;
    text-align: center;
}

.table-container{
    width: 80%;
    margin-left: -10%;
}

.apply-table{
    text-align: left;
}


  