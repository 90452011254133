/* El overlay que cubre todo el fondo, pero sin oscurecer tanto */
.loading-overlay-upload {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.1); /* Muy ligero fondo para difuminar ligeramente */
    backdrop-filter: blur(5px); /* Aplicar desenfoque al fondo */
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
    z-index: 9999; /* Asegurarse que esté encima de todo */
  }
    
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: sans-serif;
      font-size: 2.5rem;
      color: #222e99;
      background: transparent; /* Fondo transparente */
    }
    
    .loaders {
      background-color: transparent; /* Hacemos transparente el contenedor del cargador */
      box-shadow: none; /* Eliminamos cualquier sombra */
    }
    
    .loader {
      display: inline-block;
      position: relative;
      width: 50px;
      height: 50px;
      vertical-align: middle;
    }
    
    .loader-bars:before,
    .loader-bars:after,
    .loader-bars span {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 30px;
      background-color: #222e99; /* Color de los spans */
      -webkit-animation: grow 1.5s linear infinite;
      animation: grow 1.5s linear infinite;
    }
    
    .loader-bars:after {
      left: 15px;
      -webkit-animation: grow 1.5s linear -0.5s infinite;
      animation: grow 1.5s linear -0.5s infinite;
    }
    
    .loader-bars span {
      left: 30px;
      -webkit-animation: grow 1.5s linear -1s infinite;
      animation: grow 1.5s linear -1s infinite;
    }
    
    @-webkit-keyframes grow {
      0% {
        transform: scaleY(0);
        opacity: 0;
      }
      50% {
        transform: scaleY(1);
        opacity: 1;
      }
      100% {
        transform: scaleY(0);
        opacity: 0;
      }
    }
    
    @keyframes grow {
      0% {
        transform: scaleY(0);
        opacity: 0;
      }
      50% {
        transform: scaleY(1);
        opacity: 1;
      }
      100% {
        transform: scaleY(0);
        opacity: 0;
      }
    }
    