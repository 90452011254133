/* src/css/navbar.css */
.navbar {
  background: #3c5b94;
  box-shadow: 5px 5px 5px rgb(68, 68, 68);
  width: 95.5%;
  margin-left: 4%;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  height: 40px;
  margin-right: 10px;
  width: 800px;

}

.navbar-nav .nav-item:not(:last-child) {
  margin-right: 35px;
}

.dropdown-toggle::after {
  transition: transform 0.15s linear;
}

.show.dropdown .dropdown-toggle::after {
  transform: translateY(3px);
}

.dropdown-menu {
  margin-top: 0;
}

.navbar-toggler {
  border: none;
  background: transparent;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(99, 99, 99, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-message{
  color: white;
  float: right;
  margin-right: -625px;
}