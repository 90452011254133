.container-billing {
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  align-items: center; /* Centra los elementos horizontalmente */
  justify-content: flex-start; /* Alinea los elementos en la parte superior del contenedor */
  height: 100%;
  width: 85%;
  background-color: white;
  margin-left: 10%;
  margin-top: 1%;
}

.table thead {
    border-bottom: 3px solid #000; /* Línea gruesa y de color negro */
  }
  
  .table tbody tr:first-child {
    border-top: 3px solid #000; /* Línea gruesa y de color negro para asegurar que el estilo se aplique */
  }