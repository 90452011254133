.conatiner-information {
    display: flex;
    flex-direction: column; /* Apila los elementos verticalmente */
    align-items: center; /* Centra los elementos horizontalmente */
    justify-content: flex-start; /* Alinea los elementos en la parte superior del contenedor */
    height: 100%;
    width: 85%;
    background-color: white;
    margin-left: 10%;
    margin-top: 1%;
}

.table-responsive{
    width: 80%;
}