.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que el modal esté sobre otros elementos */
}

.modal-content-insertPublic {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%;
  text-align: center;
}

.modal-content-insertPublic h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.5em;
}

.modal-content-insertPublic form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espaciado entre los elementos del formulario */
}

.modal-content-insertPublic label {
  font-weight: bold;
}

.modal-content-insertPublic textarea, 
.modal-content-insertPublic input[type="file"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.modal-content-insertPublic button {
  margin-top: 20px;
}
