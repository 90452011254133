.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content-xtend {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .modal-buttons {
    margin-top: 20px;
  }
  
  .modal-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
  }
  