.notice{
    width: 90%;
    margin: 0 auto;
}

.content {
    position: relative;
    width: 85%;
    margin: 0 auto;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid rgb(211, 211, 211);
    box-sizing: border-box;
    box-shadow: 0 0 0 1px hsl(240deg 30% 28% / 5%),
      0 2px 5px 0 hsl(240deg 30% 28% / 10%), 0 1px 1px 0 hsl(0deg 0% 0% / 7%);
      overflow: visible; /* Asegura que el contenido no se recorte */
      display: flex;
      flex-direction: column; /* Organiza los hijos en columna */

}

.user{
    font-size: 16px;
    &:nth-child(1){
        color: red;
    }

    &:nth-child(2){
        color: green;
    }

    &:nth-child(3){
        color: blue;
    }

    &:nth-child(4){
        color: yellow;
    }

    &:nth-child(5){
        color: purple;
    }
}

.post{
    font-size: 20px;
}

.image img{
    width: 400px;
    height: 300px;
    margin-left: 15%;
}

.like {
    width: 95%;
    display: flex;
    flex-direction: row;
    color: gray;
    font-size: 12px;
    justify-content: start;
    /*   border: 1px solid blue; */
    margin-left: 5px;
    margin-top: 15px;
    /*   text-align: center; */
}

.like > div {
    &:nth-child(1){
        margin-right: 25px;
    }

    &:nth-child(2){
        margin-right: 25px;
    }

    &:nth-child(3){
        margin-left: 0px; 
    }
}

.iconoHeart{
    font-size: 10px;
    margin-top: 60px;
    color: gray;
    margin-top: -10px;  
}

.icono{
    font-size: 25px;
    margin-left: 10px;
}

.iconoHeart:hover{
    cursor: pointer;
    color: green
}
/* En tu archivo BoxNotice.css */
.comments-section {
    margin-top: 20px;
    overflow: hidden; /* Cambia o elimina según sea necesario */
  }
  
  .comment {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }
  
  .comment-details {
    flex-grow: 1;
  }
  
  .comment-details strong {
    color: #007BFF;
  }
  
  .comment-details p {
    margin: 5px 0;
  }
  
  .comment-details span {
    font-size: 0.8em;
    color: #666;
  }
  
  .comment-form {
    display: flex;
    flex-direction: row;
  }

  textarea {
    resize: none;
    width: 80%;
    height: 60px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .comment-form input {
    flex-grow: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .comment-form button {
    padding: 8px 12px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
    margin-top: 20px;
    margin-left: 15px;
  }
  
  .comment-form button:hover {
    background-color: #218838;
  }

  .counter{
    margin-left: 10px;
    margin-top: 35px;
  }
  