/* ModalSession.css */

/* Estilos para el overlay del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegura que el modal esté sobre otros elementos */
  }
  
  /* Estilos para el contenido del modal */
  .modal-content-change {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  /* Estilo para el título del modal */
  .modal-content-change h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5em;
  }
  
  /* Estilos para los labels e inputs */
  .modal-content-change label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
  }
  
  .modal-content-change input {
    width: calc(100% - 16px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    color: #333;
  }
  
  /* Estilos para los botones */
  .modal-content-change button {
    padding: 10px 20px;
    margin: 10px 5px;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: white;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-content-change button:hover {
    background-color: #218838;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  }
  